<div class="facilities">
    <div class="facilities__header">
        <h1 class="facilities__title">Choose Roadmap</h1>
        <img src="assets/images/logo.svg" alt="Grainger Logo" class="facilities__logo" />
    </div>
    @if (facilities$ | async; as facilities) {
        <ul class="facilities__list">
            @for (facility of facilities; track facility.id) {
                <li
                    class="facility"
                    id="facility-{{ facility.id }}"
                    [routerLink]="['/roadmap', facility.urlSlug]"
                    [ngClass]="{ disabled: facility.status !== FacilityStatus.Active }"
                >
                    <div
                        class="facility__dollhouse"
                        [graingerBackgroundImage]="facility.dollhouseImage?.thumbnailUrl"
                    ></div>
                    <h4 class="facility__name">
                        {{ facility.name }}
                    </h4>
                </li>
            }
        </ul>
    }
</div>
