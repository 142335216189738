import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { IFacility } from "@grainger/common/definitions/facility";
import { FacilityStatus } from "@grainger/common/enums/facility-status.enum";
import { Observable, tap } from "rxjs";
import { BackgroundImageDirective } from "../../core/directives/background-image.directive";
import { FacilityService } from "../../core/services/facility.service";
import { WindowSizeService } from "../../core/services/window-size.service";

@Component({
    selector: "grainger-facilities",
    imports: [CommonModule, RouterLink, BackgroundImageDirective],
    templateUrl: "./facilities.component.html",
    styleUrl: "./facilities.component.scss"
})
export class FacilitiesComponent implements OnInit {
    public facilities$: Observable<IFacility[]>;
    public isDesktop$: Observable<boolean>;

    public FacilityStatus = FacilityStatus;

    private readonly facilityService = inject(FacilityService);
    private readonly windowSizeService = inject(WindowSizeService);
    private readonly router = inject(Router);

    ngOnInit(): void {
        this.facilities$ = this.facilityService.getFacilities().pipe(
            tap((facilities) => {
                if (facilities.length === 1) {
                    const urlSlug = facilities[0].urlSlug;

                    console.log("Navigating to only available facility:", urlSlug);
                    this.router.navigate(["/roadmap", urlSlug]);
                }
            })
        );
        this.isDesktop$ = this.windowSizeService.isDesktop();
    }
}
