import { ExtraOptions, Route } from "@angular/router";
import { AuthGuard } from "@grainger/angular/guards/auth.guard";
import { ProfileGuard } from "./core/guards/profile.guard";
import { ResetPasswordGuard } from "./core/guards/reset-password.guard";
import { FacilityResolver } from "./core/resolvers/facility.resolver";
import { CompleteProfileComponent } from "./pages/complete-profile/complete-profile.component";
import { FacilitiesComponent } from "./pages/facilities/facilities.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";
import { UpdatePasswordComponent } from "./pages/update-password/update-password.component";

export const routerExtraOptions: ExtraOptions = {
    scrollPositionRestoration: "top"
};

export const appRoutes: Route[] = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "roadmaps"
    },
    {
        path: "auth/login",
        redirectTo: "login"
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "complete-profile",
        component: CompleteProfileComponent,
        canActivate: [AuthGuard.canActivateAuthenticated]
    },
    {
        path: "reset-password",
        component: UpdatePasswordComponent,
        canActivate: [ResetPasswordGuard.canActivate]
    },
    {
        path: "roadmaps",
        component: FacilitiesComponent,
        canActivate: [AuthGuard.canActivateAuthenticated, ProfileGuard.isValid]
    },
    {
        path: "roadmap/:facilitySlug",
        loadChildren: () => import("./pages/facility/facility.routes").then((r) => r.routes),
        resolve: { facility: FacilityResolver.getFacility },
        canActivate: [AuthGuard.canActivateAuthenticated, ProfileGuard.isValid]
    },
    { path: "**", redirectTo: "" }
];
