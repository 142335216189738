import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError } from "rxjs";
import { AuthService } from "../services/auth.service";

const EXCLUDED_URLS = ["auth/login", "auth/request-reset-password", "auth/update-password"] as const;

export const authInterceptor: HttpInterceptorFn = (request, next) => {
    const authService = inject(AuthService);
    return next(request).pipe(
        catchError((err: HttpErrorResponse) => {
            if (!EXCLUDED_URLS.some((ex) => request.url.includes(ex)) && err.status === 401) {
                authService.logout();
                window.location.reload();
                throw new Error("Please log in to continue");
            } else {
                throw err;
            }
        })
    );
};
