import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { IFacility } from "@grainger/common/definitions/facility";
import { Observable, finalize, map, of } from "rxjs";
import { FacilityService } from "../services/facility.service";
import { LoadingService } from "../services/loading.service";

export class FacilityResolver {
    static getFacility: ResolveFn<Observable<IFacility | null> | IFacility | null> = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) => {
        const facilityService = inject(FacilityService);
        const loadingService = inject(LoadingService);

        const facilitySlug = route.params["facilitySlug"] ?? null;

        if (!facilitySlug) {
            return of(null);
        }

        loadingService.setLoadingStatus(true);

        return facilityService.getFacility(facilitySlug).pipe(
            map((dept) => {
                return dept;
            }),
            finalize(() => loadingService.setLoadingStatus(false))
        );
    };
}
